.comboBox{
    background-color:white;
    
}
.fontColorPicker{
    background-color: '#ffff';
}
/* ------------------ */

.bold {
    font-weight: bold!important;
}

.italic {
    font-style: italic!important;
}

.underline{
    text-decoration: underline!important;
}

.center{
    bottom: 50%;
}

.bottom{
    bottom: 8px;
}
.top{
    top: 8px;
}

.paddingLeft{
    padding-right: 2.5px;
}

.paddingRight{
    padding-right: 2.5px;
}

.alignLeft{
    text-align: left;
    justify-content: flex-start;
    padding-left: 0.5rem;

}

.alignCenter{
    text-align: center;
    justify-content: center;
}

.alignRight{
    text-align: right;
    justify-content: flex-end;
    padding-right: 0.5rem;
}